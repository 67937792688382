body {
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

header {
  flex-flow: column;
  width: 100%;
  display: flex;
}

.main-image {
  background-image: url("eric-fung-Z0GZrpwcc5Y-unsplash.3dafcccb.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

.menubars-svg, #menubars-svg, #show-menu-button {
  display: none;
}

.menu {
  z-index: 7;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
  position: fixed;
}

.menu__ul {
  background-color: #fffc;
  flex-flow: row;
  justify-content: space-around;
  width: 100%;
  height: 50px;
  display: flex;
}

.main-nav-tab {
  width: 100%;
  height: 50px;
  list-style: none;
}

.main-nav-tab:hover {
  cursor: pointer;
}

.attrib {
  text-align: center;
  color: #000;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 1.1rem;
  text-decoration: none;
  display: flex;
}

a:hover {
  color: #989898;
}

.categories-list {
  text-align: center;
  opacity: 0;
  z-index: -1;
  background-color: #faebd780;
  border: 1px solid;
  flex-flow: column;
  justify-content: space-evenly;
  list-style: none;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: linear;
  display: flex;
  position: relative;
  top: -20rem;
}

.categories-list--active {
  opacity: 1;
  z-index: 1;
  transform: translateY(20rem);
}

.category-item {
  position: relative;
}

.category-item:hover {
  cursor: pointer;
  background-color: #ffffff80;
}

.menu.sticky {
  z-index: 8;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  transition: all .5s;
  display: flex;
  position: fixed;
}

.menu.hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.shoppingcart-svg {
  flex-flow: row;
  justify-content: flex-start;
  width: 25px;
  height: 25px;
  display: flex;
  left: 600px;
}

.shoppingcart-svg:hover {
  fill: #79ddff;
}

.icons-container {
  display: none;
}

.cart-number {
  text-align: center;
  background-color: #00ffd5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: .8rem;
  display: flex;
  position: absolute;
  top: .8%;
  right: 5.3%;
}

.main-nav-tab.login {
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-btn {
  border: 1px solid;
  border-radius: 10px;
  width: 100px;
  height: 30px;
  margin: 5px;
}

.login-btn:hover {
  color: #0ff;
  cursor: pointer;
}

.logo {
  z-index: 0;
  background-image: url("tamar-logo.06029664.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 140px;
  display: block;
  position: relative;
  top: 60%;
  left: 5%;
}

.modal-section {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.modal {
  display: none;
}

.modal-reveal {
  text-align: center;
  opacity: 1;
  z-index: 10;
  background-image: url("main-photo.a72f0789.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: column;
  align-items: center;
  width: 80vw;
  height: 80vh;
  margin-top: 60px;
  font-size: 2rem;
  transition: all .5s ease-in;
  display: none;
  position: fixed;
}

.subscribenow-text {
  background-color: #ffffffa9;
  border-radius: 15px;
  width: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}

.subscribe-details {
  background-color: #ffffffa9;
  border-radius: 2rem;
  flex-flow: row;
  align-items: center;
  width: auto;
  margin-top: 30px;
  margin-bottom: 2rem;
  padding: 10px;
  display: flex;
}

.subscribe-label {
  border-radius: 5%;
  margin-right: 20px;
  padding: 5px;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 1.5rem;
}

#subscribe-email {
  border-radius: 5rem;
  width: 15rem;
  height: 1.5rem;
  margin-right: 20px;
  padding: 15px;
  font-size: 1rem;
}

#submit-subscribe {
  cursor: pointer;
  background: none;
  border-radius: 2rem;
  width: 5rem;
  height: 2rem;
  margin-right: 10px;
}

#submit-subscribe:hover {
  background-color: #ffffff80;
  font-size: .9rem;
}

#subscribe-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40vw;
  height: 40vh;
}

.overlay-reveal {
  opacity: 1;
  z-index: 9;
  background-color: #fdfbfbcc;
  width: 100%;
  height: 100%;
  transition: all .5s ease-in;
  position: fixed;
  top: 0;
}

.close-modal, .close-thanks {
  color: #000;
  cursor: pointer;
  background-color: #fff0;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  position: absolute;
  top: -2rem;
  right: -2rem;
}

h1 {
  width: 95%;
  margin: 30px 0;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 2.5rem;
}

.hide {
  display: none;
}

.thanks {
  text-align: center;
  z-index: 10;
  background-color: #fffc;
  width: 40vw;
  height: 40vh;
  margin-top: 60px;
  padding-top: 10px;
  font-size: 2rem;
  position: absolute;
  top: 20%;
}

p {
  font-size: 1.3rem;
}

main {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex;
}

.slider-container {
  flex-flow: row;
  align-items: center;
  width: 95%;
  height: 65%;
  margin-top: 5rem;
  display: flex;
  position: relative;
}

.slider-wrapper .img-list .slider-image-item {
  object-fit: cover;
  height: 100%;
}

.slider-wrapper {
  flex-flow: row;
  justify-content: center;
  width: 100%;
  height: 90%;
  display: flex;
  overflow-x: hidden;
}

.img-list {
  flex-flow: row;
  width: 100%;
  display: flex;
}

.slider-image-item {
  width: 33%;
  padding: 5px;
  transition: transform 1s;
}

.slider_btns {
  cursor: pointer;
  color: #6c6c6c;
  background-color: #fffada80;
  border: .5px solid #c6aa767a;
  width: 2.5%;
  height: 75%;
  font-size: 2rem;
  position: absolute;
  top: 15%;
}

.slider-btn--left {
  left: 1.5%;
}

.slider-btn--right {
  right: 2.5%;
}

.slider_btns:hover {
  background-color: #e8e8e899;
}

.categories-container {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 50px;
  display: flex;
  top: 180%;
  box-shadow: 0 0 50px -10px #00000094;
}

.category-box {
  width: 47%;
  height: 28rem;
  margin: 10px;
  text-decoration: none;
}

.large-img-item {
  width: 100%;
  height: 100%;
}

.category-name {
  color: #fff;
  background-color: #0000001a;
  padding: 5px;
  font-size: 2rem;
  position: relative;
  bottom: 100px;
  left: 50px;
}

.go-to-top {
  background-color: #ffffffb6;
  border: 1px solid;
  width: 10%;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 5px;
  position: relative;
  top: 2rem;
}

.go-to-top:hover {
  border: #716521, solid;
  cursor: pointer;
  background-color: #6f86a960;
}

.footer {
  background-color: #a1a0413f;
  width: 100%;
}

.columns-container {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.footer-right-column, .footer-middle-column, .footer-left-column {
  flex-flow: column;
  align-items: center;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
}

.attrib-footer {
  color: #000;
  border-bottom: .5px solid #424242;
  flex-flow: row;
  align-items: center;
  width: auto;
  height: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
}

.attrib-footer:hover {
  color: #000;
}

.rights-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  display: flex;
}

.rights-text {
  color: #fff;
}
/*# sourceMappingURL=index.38b635d7.css.map */
